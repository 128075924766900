@import url('https://fonts.googleapis.com/css?family=Open+Sans');

#inner {
  padding: 10px;
  /* padding-bottom:13rem;   Height of the footer */
}

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 13rem;
  /* Height of the footer */
}

body {
  margin: 0;
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(255, 255, 255);
  padding: 0;
  height: 100%;
}

#root {
  min-height: 100vh;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.table thead th {
  height: 6rem;
  font-family: 'Open Sans';
}

.table tbody td {
  font-family: 'Open Sans';
}

.card-header-custom {
  background-color: #8D6E63;
  color: #ffffff;
  padding: .75rem 1.25rem;
  margin-bottom: 0;
  border-bottom: 1px solid rgba(0, 0, 0, .125);
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
}

.card {
  /* width: 500px;
  height: 325px; */
  background: #F4F6FB;
  border: 1px solid white;
  box-shadow: 10px 10px 64px 0px rgba(180, 180, 207, 0.75);
  -webkit-box-shadow: 10px 10px 64px 0px rgba(186, 186, 202, 0.75);
  -moz-box-shadow: 10px 10px 64px 0px rgba(208, 208, 231, 0.75);
  border-top-left-radius: 1.5rem !important;
  border-top-right-radius: 1.5rem !important;
  margin: auto;
}

.cardProduct{
  background: #F4F6FB;
  border: 1px solid white;
  margin: auto;
  border-radius: 1.5rem !important;
}

.card-login {
/* .MuiCard-root{ */
  max-width: 500px;
  /* height: 325px; */
  background: #F4F6FB;
  border: 1px solid white;
  box-shadow: 10px 10px 64px 0px rgba(180, 180, 207, 0.75);
  -webkit-box-shadow: 10px 10px 64px 0px rgba(186, 186, 202, 0.75);
  -moz-box-shadow: 10px 10px 64px 0px rgba(208, 208, 231, 0.75);
  border-radius: 1.5rem !important;
  margin: auto;
}

.card-register {
  max-width: 1000px;
  /* height: 325px; */
  background: #F4F6FB;
  border: 1px solid white;
  box-shadow: 10px 10px 64px 0px rgba(180, 180, 207, 0.75);
  -webkit-box-shadow: 10px 10px 64px 0px rgba(186, 186, 202, 0.75);
  -moz-box-shadow: 10px 10px 64px 0px rgba(208, 208, 231, 0.75);
  border-radius: 1.5rem !important;
  margin: auto;
}

.form-label-register {
  display: flex;
  justify-content: center;
  align-items: center;
}

.collapsible .content {
  padding: 6px;
  background-color: #eeeeee;
}

.collapsible .header {
  background-color: #dddddd;
  padding: 6px;
  cursor: pointer;
}

.btn-primary {
  background: #6D4D42 !important;
}

.notVisibleProduct {
  background-color: #ff4000;
  opacity: 0.2;
}

.noShopPicture{
  width: 100%;
  height: calc(100vw/1.6180339);
  background-color: #ECECEC;
  margin: 0px;
  border-radius: 0 0 25px 25px;
}

.shopPicture{
  width: 100%;
  height: calc(100vw/1.6180339);
  margin: 0px;
  border-radius: 0 0 25px 25px;
}

.containerShop{
  width: 100%;
  position: relative;
  display: inline-block;
}

.textShop{
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white
}

.textTableNumber{
  display: flex;
  position: absolute;
  top: 15%;
  left: 50%;  
  width: 120px;
  border-radius: 10px 10px 10px 10px;
  background-color: rgba(0, 0, 0, 0.123);
  transform: translate(-50%, -50%);
  color: white;
  justify-content: center;
}

.gradient{
  position: absolute;
  width: 100%;
  height: calc(100vw/1.6180339);
  border-radius: 0 0 25px 25px;
  background: linear-gradient(to bottom, transparent 0 60%, rgba(0, 0, 0, 0.87));
}

.banner{
  /* margin-top: 20px; */
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #6D4D42;
  position: fixed;
  bottom: 0;
}

.divider{
  position: relative;
  margin-top: 2%;
  margin-bottom: 2%;
  height: 1px;
}

.div-transparent:before{
  content: "";
  position: absolute;
  top: 0;
  left: 5%;
  right: 5%;
  width: 90%;
  height: 1px;
  background-image: linear-gradient(to right, transparent, rgb(48,49,51), transparent);
}
